import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { Banner } from '@components/banner';
import { CustomText, Skeleton } from '@components/ui';
import { SORT_CODE } from '@components/filter/constants';
import { ProductListSort, useGetCategoryQuery, useProductCardsSummaryQuery } from 'src/generated/graphql';
import { numberWithCommas } from '@lib';
import useProducts from '@hooks/useProducts';
import NoItemsMessage from '@components/common/Products/NoItemsMessage';
// import Products from '@components/common/Products/Products';
import { Products } from '@components/common/Products/Grid';
import ProductFilter from '@components/filter/ProductFilter';
import * as mixpanel from '@lib/mixpanel';
import { GridLikeWrapper } from '@components/layout/Grid/Grid.styles';
import { PAGE_NAME } from '../../constants';

type Props = {
  pageType: PAGE_NAME;
  categoryCode?: string;
  sort?: (typeof SORT_CODE)[keyof typeof SORT_CODE];
  isFilter?: boolean;
  isSort?: boolean;
  isFreeSort?: boolean;
  isBanner?: boolean;
  listMiddleBanner?: React.ReactElement;
};

const CategoryListWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 80px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 36px;
    margin-bottom: 56px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 24px;
    margin-bottom: 56px;
  }
  div.category-banner {
    max-width: 1444px;
    margin: 0 auto;
    padding: 0;
  }
`;

export default function CategoryList({ categoryCode, pageType, sort = null, isFilter = true, isFreeSort = true, isBanner = true, listMiddleBanner = null }: Props) {
  const { t, i18n } = useTranslation(['category', 'productItems']);
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useGetCategoryQuery({
    variables: { godoCateCd: categoryCode },
    skip: !isBanner,
  });
  const isCategoryLoading = Boolean(categoryLoading || categoryError);
  const category = isBanner && !isCategoryLoading && categoryData?.getCategory?.trans?.find((x) => x.lang === i18n.language);

  const {
    data: summaryData,
    loading: summaryLoading,
    error: summaryError,
  } = useProductCardsSummaryQuery({
    variables: {
      categoryCode: categoryCode,
      sort: sort as ProductListSort,
      isNew: pageType === PAGE_NAME.NEW,
    },
  });
  const isSummaryLoading = summaryLoading || summaryError;
  const productSummary = summaryData?.productCardsSummary;

  const isLoading = categoryLoading || isSummaryLoading;
  const products = useProducts({
    pageType,
    sort: productSummary?.sort || SORT_CODE.NEWEST,
    isFreeSort,
    summaryProducts: productSummary?.products,
  });

  useEffect(() => {
    if (products.isLoading) {
      if (categoryCode) {
        mixpanel.event('Product List', {
          type: 'category',
          Extensions: products.selectedExtensions,
          sort: products.selectedSortOrder,
          'category code': categoryCode,
        });
      } else {
        mixpanel.event('Product List', {
          type: 'new',
          Extensions: products.selectedExtensions,
        });
      }
    }
  }, [products.selectedExtensions, products.selectedSortOrder, products.isLoading]);

  useEffect(() => {
    if (categoryCode) products.cache.clearAll();
  }, [categoryCode]);

  return (
    <CategoryListWrapper>
      <GridLikeWrapper>
        {isBanner && category?.visibleResultStats && (
          <>
            <Banner color={'#f7f7fc'} className={'category-banner'}>
              <>
                {!isLoading && category?.name && (
                  <CustomText size={20} weight={700}>
                    {category.name.replace(/#(.*?)#/g, '')}
                  </CustomText>
                )}
                <CustomText size={20} className={'ml-2.5'}>
                  {t('category:resultText', {
                    count: numberWithCommas(products.filterCount) as never,
                  })}
                </CustomText>
              </>
            </Banner>

            <div className={'mb-10'} />
          </>
        )}

        {/** 필터 영역 */}
        {isFilter && (
          <>
            {!isLoading ? (
              <ProductFilter {...products} totalCount={productSummary.products.length} pageType={pageType} keyword={category?.name?.replace(/#(.*?)#/g, '')} />
            ) : (
              <Skeleton width={'100%'} height={'104px'} />
            )}
          </>
        )}
      </GridLikeWrapper>

      {/** 상품 목록 영역 */}
      {!products.isLoading && (products.products || []).length === 0 ? (
        <NoItemsMessage title={t('productItems:noItemTextTop')} description={t('productItems:noItemTextBottom')} />
      ) : (
        (() => {
          if (listMiddleBanner) {
            return (
              <>
                <Products
                  {...products}
                  products={(products.products || []).slice(0, 9)}
                  isVirtual={false}
                  nextOffset={null}
                  isLoading={products.isLoading && (products.products || []).length === 0}
                />
                {listMiddleBanner}
                <Products {...products} products={(products.products || []).slice(9)} filterCount={products.filterCount - 9} isVirtual={false} />
              </>
            );
          }

          return <Products {...products} />;
        })()
      )}
    </CategoryListWrapper>
  );
}
